$theme-mode: null !default;

@use "devextreme/scss/widgets/fluent/colors" as * with ($color: "blue", $mode: $theme-mode);
@use "devextreme/scss/widgets/fluent/sizes" as * with ($size: "default");
@use "devextreme/scss/widgets/fluent/fieldset/sizes" as *;
@use "theme-special" as * with ($theme: "fluent");
@use "sass:math";
@use "sass:color";

:root {
  --dark-warning-color: #{darken(adjust-hue($base-warning, -12), 3.73)};
  --typography-bg: var(--base-bg);

  @if variable-exists("base-typography-bg") {
    --typography-bg: var(--dx-color-main-bg);
  }

  --side-panel-min-width: 48px;
  --content-padding: 24px;
  --page-padding: 32px;
  --header-toolbar-vertical-padding: 10px;
  --footer-height: 70px;
  --toolbar-vertical-padding: 16px;
  --toolbar-margin-bottom: var(--content-padding);
  --base-bg: var(--dx-component-color-bg);
  --base-bg-darken-5: #{darken($base-bg, 5)};
  --base-bg-darken-4: #{darken($base-bg, 4)};
  --side-panel-background: var(--base-bg-darken-5);
  --card-background: var(--base-bg);
  --calendar-width: 292px;

  @if $theme-mode == dark {
    --card-background: var(--base-bg-darken-5);
  }

  --border-color: var(--dx-color-border);
  --accent-color: var(--dx-color-primary);
  --success-color: var(--dx-color-success);
  --info-color: var(--dx-color-primary);
  --warning-color: var(--dx-color-warning);
  --error-color: var(--dx-color-danger);
  --base-text-color: var(--dx-color-text);
  --background-gray-color: #{color.change($base-text-color, $alpha: 0.04)};
  --texteditor-edit-color: var(--dx-texteditor-color-text);
  --texteditor-label-color: var(--dx-texteditor-color-label);
  --base-text-color-alpha: #{rgba($base-text-color, alpha($base-text-color) * 0.7)};
  --toolbar-height: var(--dx-toolbar-height);
  --filled-texteditor-input-horizontal-padding: #{$fluent-field-value-horizontal-padding};
  --list-padding-left: var(--dx-list-item-padding-inline);
  --list-padding-vertical: var(--dx-list-item-padding-block);
  --footer-left-padding: calc((var(--side-panel-min-width) - var(--dx-font-size-icon)) / 2);
  --popup-toolbar-item-spacing: var(--dx-popup-toolbar-item-padding-inline);
  --drop-down-gnrc-button-height: var(--dx-component-height);
  --theme-padding: var(--dx-button-padding-inline);
  --tooltip-danger-button-color: #{color.change($base-danger, $alpha: 0.1)};
  --tooltip-success-button-color: #{color.change($base-success, $alpha: 0.1)};
  --subtitle-text-color: var(--texteditor-label-color);
  --button-height-theme-difference: calc((var(--theme-padding) - 10px) / 3); // WA for no button-height in fluent theme
  --button-height: calc(26px + var(--button-height-theme-difference)); // remove when design-system is released
  --scheduler-toolbar-height: calc(var(--button-height) + 2 * var(--theme-padding));
}